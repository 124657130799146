<template>
  <v-app id="default-layout" light :class="{ 'fix-mac': isMacOS }">
    <toolbar />
    <nuxt style="flex: 1; width: 100%" />
    <Footer />
    <cookie-banner />
  </v-app>
</template>

<script>
export default {
  name: "DefaultLayout",
  components: {
    Toolbar: () => import("~/components/website/Toolbar"),
    CookieBanner: () => import("~/components/website/CookieBanner"),
    Footer: () => import("~/components/website/Footer"),
  },
  computed: {
    isMacOS() {
      // console.log(navigator.platform.indexOf("Mac") === 0)
      return (
        navigator.platform.indexOf("Mac") === 0 ||
        navigator.platform === "iPhone"
      );
    },
  },
  mounted() {
    this.$vuetify.theme.dark = false;
  },
};
</script>

<style lang="scss" src="~/assets/css/website/index.scss" />
