export default function ({ $axios, $toast, $dayjs, store, $sentry }) {
  $axios.onRequest((config) => {
    store.dispatch('loader/start', $dayjs())

    if (store.getters['auth/token']) {
      config.headers.Authorization = `Bearer ${store.getters['auth/token']}`
    } else {
      delete config.headers.Authorization
    }
  })

  $axios.onResponse(() => {
    store.dispatch('loader/finish')
  })

  $axios.onError((error) => {
    store.dispatch('loader/finish')

    const message = error.response?.data?.message || error.message
    const status = error.response?.status || 500

    if (message?.length && status !== 422 && status !== 400 && status !== 403 && status !== 406 && status !== 411) {
      $toast.clear()
      $toast.error(message)
    }

    if (status >= 500) {
      const sentryMsg = message || `${status} error on AxiosRequest`
      $sentry.captureException(new Error(`${status}: ${sentryMsg}`, { cause: error }))
      return Promise.resolve()
    }

    if (status === 401) {
      store.dispatch('auth/logout')
      return Promise.resolve()
    }
  })
}
