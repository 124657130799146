import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _88ec8c78 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _16610264 = () => interopDefault(import('../pages/cupom.vue' /* webpackChunkName: "pages/cupom" */))
const _8b663a70 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _8e97efec = () => interopDefault(import('../pages/perfil.vue' /* webpackChunkName: "pages/perfil" */))
const _ac6e1ad4 = () => interopDefault(import('../pages/regulamentos.vue' /* webpackChunkName: "pages/regulamentos" */))
const _1c93abd2 = () => interopDefault(import('../pages/vencedores.vue' /* webpackChunkName: "pages/vencedores" */))
const _59987532 = () => interopDefault(import('../pages/admin/actions/index.vue' /* webpackChunkName: "pages/admin/actions/index" */))
const _53f230bb = () => interopDefault(import('../pages/admin/calculation.vue' /* webpackChunkName: "pages/admin/calculation" */))
const _b4af14ee = () => interopDefault(import('../pages/admin/commands/index.vue' /* webpackChunkName: "pages/admin/commands/index" */))
const _0ae403e4 = () => interopDefault(import('../pages/admin/cpf-block/index.vue' /* webpackChunkName: "pages/admin/cpf-block/index" */))
const _4e60e4f4 = () => interopDefault(import('../pages/admin/dashboard.vue' /* webpackChunkName: "pages/admin/dashboard" */))
const _d6fa07ae = () => interopDefault(import('../pages/admin/dispatches/index.vue' /* webpackChunkName: "pages/admin/dispatches/index" */))
const _33b96ebd = () => interopDefault(import('../pages/admin/email-templates/index.vue' /* webpackChunkName: "pages/admin/email-templates/index" */))
const _0aa1b618 = () => interopDefault(import('../pages/admin/exports/index.vue' /* webpackChunkName: "pages/admin/exports/index" */))
const _574d690b = () => interopDefault(import('../pages/admin/faq/index.vue' /* webpackChunkName: "pages/admin/faq/index" */))
const _ef4ae516 = () => interopDefault(import('../pages/admin/indexes/index.vue' /* webpackChunkName: "pages/admin/indexes/index" */))
const _bafaea10 = () => interopDefault(import('../pages/admin/invalid-prizes/index.vue' /* webpackChunkName: "pages/admin/invalid-prizes/index" */))
const _69403067 = () => interopDefault(import('../pages/admin/invoices/index.vue' /* webpackChunkName: "pages/admin/invoices/index" */))
const _7feedabe = () => interopDefault(import('../pages/admin/ips/index.vue' /* webpackChunkName: "pages/admin/ips/index" */))
const _334966f9 = () => interopDefault(import('../pages/admin/permissions/index.vue' /* webpackChunkName: "pages/admin/permissions/index" */))
const _d320203c = () => interopDefault(import('../pages/admin/prizes/index.vue' /* webpackChunkName: "pages/admin/prizes/index" */))
const _5f6c7f4f = () => interopDefault(import('../pages/admin/registrations/index.vue' /* webpackChunkName: "pages/admin/registrations/index" */))
const _0a4f9a3e = () => interopDefault(import('../pages/admin/regulations/index.vue' /* webpackChunkName: "pages/admin/regulations/index" */))
const _7bbe0b21 = () => interopDefault(import('../pages/admin/statistics.vue' /* webpackChunkName: "pages/admin/statistics" */))
const _48d4469c = () => interopDefault(import('../pages/admin/tickets/index.vue' /* webpackChunkName: "pages/admin/tickets/index" */))
const _f13ff8ca = () => interopDefault(import('../pages/admin/tokens/index.vue' /* webpackChunkName: "pages/admin/tokens/index" */))
const _628fa65a = () => interopDefault(import('../pages/admin/total-tickets/index.vue' /* webpackChunkName: "pages/admin/total-tickets/index" */))
const _2f5bb6fd = () => interopDefault(import('../pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _a514f8ee = () => interopDefault(import('../pages/admin/winners/index.vue' /* webpackChunkName: "pages/admin/winners/index" */))
const _a50215c4 = () => interopDefault(import('../pages/admin/winners-draw/index.vue' /* webpackChunkName: "pages/admin/winners-draw/index" */))
const _4d5de5c8 = () => interopDefault(import('../pages/auth/forgot.vue' /* webpackChunkName: "pages/auth/forgot" */))
const _6f81e480 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _09e23748 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _5261d2dc = () => interopDefault(import('../pages/admin/auth/forgot.vue' /* webpackChunkName: "pages/admin/auth/forgot" */))
const _526166c0 = () => interopDefault(import('../pages/admin/auth/login.vue' /* webpackChunkName: "pages/admin/auth/login" */))
const _2390d3b5 = () => interopDefault(import('../pages/admin/dispatches/create.vue' /* webpackChunkName: "pages/admin/dispatches/create" */))
const _6b28b3a1 = () => interopDefault(import('../pages/admin/email-templates/create.vue' /* webpackChunkName: "pages/admin/email-templates/create" */))
const _8bd7f9da = () => interopDefault(import('../pages/admin/faq/create.vue' /* webpackChunkName: "pages/admin/faq/create" */))
const _6757f840 = () => interopDefault(import('../pages/admin/regulations/create.vue' /* webpackChunkName: "pages/admin/regulations/create" */))
const _e7568afa = () => interopDefault(import('../pages/admin/tokens/create.vue' /* webpackChunkName: "pages/admin/tokens/create" */))
const _3861193e = () => interopDefault(import('../pages/admin/users/create.vue' /* webpackChunkName: "pages/admin/users/create" */))
const _2a13f960 = () => interopDefault(import('../pages/admin/winners-draw/create.vue' /* webpackChunkName: "pages/admin/winners-draw/create" */))
const _0c01a404 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _67acad9a = () => interopDefault(import('../pages/admin/auth/reset/_token.vue' /* webpackChunkName: "pages/admin/auth/reset/_token" */))
const _275fa71a = () => interopDefault(import('../pages/admin/email-templates/edit/_id.vue' /* webpackChunkName: "pages/admin/email-templates/edit/_id" */))
const _68b4e20c = () => interopDefault(import('../pages/admin/faq/edit/_id.vue' /* webpackChunkName: "pages/admin/faq/edit/_id" */))
const _565f800e = () => interopDefault(import('../pages/admin/regulations/edit/_id.vue' /* webpackChunkName: "pages/admin/regulations/edit/_id" */))
const _115576da = () => interopDefault(import('../pages/admin/users/edit/_id.vue' /* webpackChunkName: "pages/admin/users/edit/_id" */))
const _4edf0dce = () => interopDefault(import('../pages/admin/winners-draw/edit/_id.vue' /* webpackChunkName: "pages/admin/winners-draw/edit/_id" */))
const _7a73c7cc = () => interopDefault(import('../pages/admin/actions/_id.vue' /* webpackChunkName: "pages/admin/actions/_id" */))
const _7478165e = () => interopDefault(import('../pages/admin/dispatches/_id.vue' /* webpackChunkName: "pages/admin/dispatches/_id" */))
const _00a6eb60 = () => interopDefault(import('../pages/admin/invalid-prizes/_id.vue' /* webpackChunkName: "pages/admin/invalid-prizes/_id" */))
const _67c5bee2 = () => interopDefault(import('../pages/admin/invoices/_id.vue' /* webpackChunkName: "pages/admin/invoices/_id" */))
const _2e543956 = () => interopDefault(import('../pages/admin/ips/_ip.vue' /* webpackChunkName: "pages/admin/ips/_ip" */))
const _48c6fa6c = () => interopDefault(import('../pages/admin/prizes/_id.vue' /* webpackChunkName: "pages/admin/prizes/_id" */))
const _f13fed12 = () => interopDefault(import('../pages/admin/registrations/_id.vue' /* webpackChunkName: "pages/admin/registrations/_id" */))
const _000d1bdb = () => interopDefault(import('../pages/admin/validation/_prize.vue' /* webpackChunkName: "pages/admin/validation/_prize" */))
const _f257991a = () => interopDefault(import('../pages/auth/reset/_token.vue' /* webpackChunkName: "pages/auth/reset/_token" */))
const _2c3f872e = () => interopDefault(import('../pages/unsubscribe/_id.vue' /* webpackChunkName: "pages/unsubscribe/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _88ec8c78,
    name: "admin"
  }, {
    path: "/cupom",
    component: _16610264,
    name: "cupom"
  }, {
    path: "/faq",
    component: _8b663a70,
    name: "faq"
  }, {
    path: "/perfil",
    component: _8e97efec,
    name: "perfil"
  }, {
    path: "/regulamentos",
    component: _ac6e1ad4,
    name: "regulamentos"
  }, {
    path: "/vencedores",
    component: _1c93abd2,
    name: "vencedores"
  }, {
    path: "/admin/actions",
    component: _59987532,
    name: "admin-actions"
  }, {
    path: "/admin/calculation",
    component: _53f230bb,
    name: "admin-calculation"
  }, {
    path: "/admin/commands",
    component: _b4af14ee,
    name: "admin-commands"
  }, {
    path: "/admin/cpf-block",
    component: _0ae403e4,
    name: "admin-cpf-block"
  }, {
    path: "/admin/dashboard",
    component: _4e60e4f4,
    name: "admin-dashboard"
  }, {
    path: "/admin/dispatches",
    component: _d6fa07ae,
    name: "admin-dispatches"
  }, {
    path: "/admin/email-templates",
    component: _33b96ebd,
    name: "admin-email-templates"
  }, {
    path: "/admin/exports",
    component: _0aa1b618,
    name: "admin-exports"
  }, {
    path: "/admin/faq",
    component: _574d690b,
    name: "admin-faq"
  }, {
    path: "/admin/indexes",
    component: _ef4ae516,
    name: "admin-indexes"
  }, {
    path: "/admin/invalid-prizes",
    component: _bafaea10,
    name: "admin-invalid-prizes"
  }, {
    path: "/admin/invoices",
    component: _69403067,
    name: "admin-invoices"
  }, {
    path: "/admin/ips",
    component: _7feedabe,
    name: "admin-ips"
  }, {
    path: "/admin/permissions",
    component: _334966f9,
    name: "admin-permissions"
  }, {
    path: "/admin/prizes",
    component: _d320203c,
    name: "admin-prizes"
  }, {
    path: "/admin/registrations",
    component: _5f6c7f4f,
    name: "admin-registrations"
  }, {
    path: "/admin/regulations",
    component: _0a4f9a3e,
    name: "admin-regulations"
  }, {
    path: "/admin/statistics",
    component: _7bbe0b21,
    name: "admin-statistics"
  }, {
    path: "/admin/tickets",
    component: _48d4469c,
    name: "admin-tickets"
  }, {
    path: "/admin/tokens",
    component: _f13ff8ca,
    name: "admin-tokens"
  }, {
    path: "/admin/total-tickets",
    component: _628fa65a,
    name: "admin-total-tickets"
  }, {
    path: "/admin/users",
    component: _2f5bb6fd,
    name: "admin-users"
  }, {
    path: "/admin/winners",
    component: _a514f8ee,
    name: "admin-winners"
  }, {
    path: "/admin/winners-draw",
    component: _a50215c4,
    name: "admin-winners-draw"
  }, {
    path: "/forgot",
    component: _4d5de5c8,
    name: "auth-forgot"
  }, {
    path: "/login",
    component: _6f81e480,
    name: "auth-login"
  }, {
    path: "/register",
    component: _09e23748,
    name: "auth-register"
  }, {
    path: "/admin/forgot",
    component: _5261d2dc,
    name: "admin-auth-forgot"
  }, {
    path: "/admin/login",
    component: _526166c0,
    name: "admin-auth-login"
  }, {
    path: "/admin/dispatches/create",
    component: _2390d3b5,
    name: "admin-dispatches-create"
  }, {
    path: "/admin/email-templates/create",
    component: _6b28b3a1,
    name: "admin-email-templates-create"
  }, {
    path: "/admin/faq/create",
    component: _8bd7f9da,
    name: "admin-faq-create"
  }, {
    path: "/admin/regulations/create",
    component: _6757f840,
    name: "admin-regulations-create"
  }, {
    path: "/admin/tokens/create",
    component: _e7568afa,
    name: "admin-tokens-create"
  }, {
    path: "/admin/users/create",
    component: _3861193e,
    name: "admin-users-create"
  }, {
    path: "/admin/winners-draw/create",
    component: _2a13f960,
    name: "admin-winners-draw-create"
  }, {
    path: "/",
    component: _0c01a404,
    name: "index"
  }, {
    path: "/admin/reset/:token?",
    component: _67acad9a,
    name: "admin-auth-reset-token"
  }, {
    path: "/admin/email-templates/edit/:id",
    component: _275fa71a,
    name: "admin-email-templates-edit-id"
  }, {
    path: "/admin/faq/edit/:id?",
    component: _68b4e20c,
    name: "admin-faq-edit-id"
  }, {
    path: "/admin/regulations/edit/:id?",
    component: _565f800e,
    name: "admin-regulations-edit-id"
  }, {
    path: "/admin/users/edit/:id?",
    component: _115576da,
    name: "admin-users-edit-id"
  }, {
    path: "/admin/winners-draw/edit/:id",
    component: _4edf0dce,
    name: "admin-winners-draw-edit-id"
  }, {
    path: "/admin/actions/:id",
    component: _7a73c7cc,
    name: "admin-actions-id"
  }, {
    path: "/admin/dispatches/:id",
    component: _7478165e,
    name: "admin-dispatches-id"
  }, {
    path: "/admin/invalid-prizes/:id?",
    component: _00a6eb60,
    name: "admin-invalid-prizes-id"
  }, {
    path: "/admin/invoices/:id",
    component: _67c5bee2,
    name: "admin-invoices-id"
  }, {
    path: "/admin/ips/:ip",
    component: _2e543956,
    name: "admin-ips-ip"
  }, {
    path: "/admin/prizes/:id",
    component: _48c6fa6c,
    name: "admin-prizes-id"
  }, {
    path: "/admin/registrations/:id",
    component: _f13fed12,
    name: "admin-registrations-id"
  }, {
    path: "/admin/validation/:prize?",
    component: _000d1bdb,
    name: "admin-validation-prize"
  }, {
    path: "/reset/:token?",
    component: _f257991a,
    name: "auth-reset-token"
  }, {
    path: "/unsubscribe/:id?",
    component: _2c3f872e,
    name: "unsubscribe-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
