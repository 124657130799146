const Menu = [
  { header: 'Administração' },
  {
    title: 'Dashboard',
    icon: 'dashboard',
    href: '/admin/dashboard',
    permission: 'dashboard'
  },
  {
    href: '/admin/users/create',
    permission: 'users:create',
    hidden: true
  },
  {
    href: '/admin/users/edit/',
    permission: 'users:edit',
    hidden: true
  },
  {
    title: 'Usuários',
    icon: 'admins',
    href: '/admin/users',
    permission: 'users'
  },
  {
    title: 'Permissões',
    icon: 'permissions',
    href: '/admin/permissions',
    permission: 'permissions'
  },
  {
    href: '/admin/tokens/create',
    permission: 'tokens:create',
    hidden: true
  },
  {
    title: 'Tokens - API',
    icon: 'tokens',
    href: '/admin/tokens',
    permission: 'tokens'
  },
  {
    title: 'Estatísticas',
    icon: 'statistics',
    href: '/admin/statistics',
    permission: 'statistics'
  },
  {
    title: 'FAQ',
    icon: 'faq',
    href: '/admin/faq',
    permission: 'faq'
  },
  {
    title: 'Regulamentos',
    icon: 'regulation',
    href: '/admin/regulations',
    permission: 'regulations'
  },
  {
    href: '/admin/winners-draw/create',
    permission: 'draw:create',
    hidden: true
  },
  {
    href: '/admin/winners-draw/edit/',
    permission: 'draw:edit',
    hidden: true
  },
  {
    title: 'Vencedores Sorteio',
    icon: 'winnerDraw',
    href: '/admin/winners-draw',
    permission: 'draw'
  },
  { divider: true, permission: 'calculation' },
  {
    title: 'Apuração',
    icon: 'abacus',
    href: '/admin/calculation',
    permission: 'calculation'
  },
  { header: 'Promoção' },
  { divider: true, permission: 'faq' },
  {
    title: 'Cadastros',
    icon: 'registrations',
    href: '/admin/registrations',
    permission: 'registrations'
  },
  {
    title: 'Notas / Pedidos',
    icon: 'invoices',
    href: '/admin/invoices',
    permission: 'invoices'
  },
  { divider: true },
  {
    title: 'Números da Sorte',
    icon: 'tickets',
    href: '/admin/tickets',
    permission: 'tickets'
  },
  {
    title: 'Total Números da Sorte',
    icon: 'ticketTotal',
    href: '/admin/total-tickets',
    permission: 'tickets'
  },
  {
    title: 'Prêmios Instantâneos',
    icon: 'prizes.default',
    href: '/admin/prizes',
    permission: 'prizes'
  },
  { divider: true },
  {
    title: 'Usuários Premiados',
    icon: 'winners',
    href: '/admin/winners',
    permission: 'winners'
  },
  {
    title: 'Prêmios Invalidados',
    icon: 'prizes.invalid',
    href: '/admin/invalid-prizes',
    permission: 'invalid-prizes'
  },
  {
    title: 'CPFs bloqueados',
    icon: 'account.canceld',
    href: '/admin/cpf-block',
    permission: 'cpf-block'
  },
  { header: 'Logs', permission: 'actions' },
  {
    title: "IP's",
    icon: 'ip',
    href: '/admin/ips',
    permission: 'ips'
  },
  {
    title: 'Emails',
    icon: 'dispatches',
    href: '/admin/dispatches',
    permission: 'dispatches'
  },
  {
    title: 'Ações',
    icon: 'actions',
    href: '/admin/actions',
    permission: 'actions'
  },
  {
    title: 'Comandos',
    icon: 'commands',
    href: '/admin/commands',
    permission: 'commands'
  },
  {
    title: 'Indexes',
    icon: 'indexes',
    href: '/admin/indexes',
    permission: 'commands'
  },
  {
    title: 'Exportações',
    icon: 'exports',
    href: '/admin/exports',
    permission: 'exports'
  },
  { header: 'Conta' },
  {
    title: 'Sair',
    icon: 'logout',
    logout: true
  }
]

export default Menu
